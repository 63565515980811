import $ from 'jquery';
import { isDevelopment } from './utlis';
import './pagination.js';
import '../styles/pagination.css';

/**
 * pc 处理scroll动画
 */
 let isAtBottom = false;
 function listenScroll() {
     $('.special-content').off('scroll').on('scroll', function() {
         if (!$(this)[0]) {
             return;
         }
         // 返回顶部
         const { scrollHeight, scrollTop } = $(this)[0];
         const { height: domHeight } = $(this)[0].getBoundingClientRect();
         if (scrollTop + domHeight >= scrollHeight - 10) {
             $('.special-content__back').addClass('showBack');
             isAtBottom = true;
         } else {
             $('.special-content__back').removeClass('showBack');
             isAtBottom = false;
         }
 
         // 头部伸缩
         if (scrollTop > 186) {
             $('.special-header').addClass('scrollTop');
             $(this).addClass('scrollHeight');
             return;
         }
         $('.special-header').removeClass('scrollTop');
         $(this).removeClass('scrollHeight');
     });
 }

/**
 * mobile 处理scroll动画
 */
 function listenMbScroll() {
    $('.special-mobile').off('scroll').on('scroll', function() {
        if (!$(this)[0]) {
            return;
        }
        // 头部伸缩
        const { scrollTop } = $(this)[0];
        if (scrollTop > 30) {
            $('.special-banner__bottom__text').fadeOut('fast');
            $('.special-banner').addClass('scrollTop');
            return;
        }
        $('.special-banner__bottom__text').fadeIn('fast');
        $('.special-banner').removeClass('scrollTop');
    });
}

/**
 * 获取渲染 special
 */
// eslint-disable-next-line no-unused-vars
function createPeriodical(data) {
    let periodicalItem = '';
    for (let index = 0; index < data.length; index++) {
        const currItem = data[index];
        periodicalItem += `<div class="special-content__card__item" url_link=${currItem.link}>
            <span>${currItem.desc}</span>
        </div>`;
    }
    $('.special .special-content__card').append(periodicalItem);
    $('.special-content__card__item').each(function() {
        $(this).off('click').on('click', function() {
            console.log(222, this);
        let url = $(this).attr('url_link');
          window.location.href = url;
        });
    });
}

/**
 * mobile 获取渲染special
 */

 function createMbPeriodical(data) {
    let periodicalItem = '';
    for (let index = 0; index < data.length; index++) {
        const currItem = data[index];
        periodicalItem += `<div class="special-m-content__item" url_link=${currItem.link}>
            <span>${currItem.desc}</span>
        </div>`;
    }
    $('.special-m-content').append(periodicalItem);
    $('.special-m-content__item').each(function() {
        $(this).off('click').on('click', function() {
            let url = $(this).attr('url_link');
            window.location.href = url;
        });
    });
}

// 当前页码
let pageNum = 1;
let mbPageNum = 1;
// pageSize
const PAGE_SIZE = 16;
// 所有数据
let specialData = [];
// 当前渲染数据
let currData = [];
let mbCurrData = [];

/**
 * pc 获取当前页面数据并渲染
 */
function renderCurrPage() {
    currData = specialData.slice((pageNum - 1) * PAGE_SIZE, (pageNum - 1) * PAGE_SIZE + PAGE_SIZE);
    // 清空数据
    $('.special-pc .special-content__card').empty();
    isDevelopment && console.log('currData', currData, specialData);
    // 渲染数据
    createPeriodical(currData);
}

/**
 * mobile 获取当前页面数据并渲染
 */
function renderMbCurrPage() {
    // 当前页码数据
    mbCurrData = specialData.slice((mbPageNum - 1) * PAGE_SIZE, (mbPageNum - 1) * PAGE_SIZE + PAGE_SIZE);
    // 渲染数据
    createMbPeriodical(mbCurrData);
}

// eslint-disable-next-line no-unused-vars
function changePageNum(e) {
    pageNum = parseInt(e.currentTarget.innerText, 10);
    renderCurrPage();
}

// eslint-disable-next-line no-unused-vars
function changeNextPre(type) {
    if(type === 'pre') {
        pageNum -= 1;
        renderCurrPage();
        return;
    }
    pageNum += 1;
    renderCurrPage();
}

// eslint-disable-next-line no-unused-vars
function fetchPeriodicalData() {
    $.get('https://api.simeji.me/simeji-appui/config/getstablev2', {
        conf_key: 'simeji.all.simejiwebsite-special',
    },
        (res) => {
            isDevelopment && console.log(res);
            if (res.errno === 0) {
                specialData = res.data.list;
                renderCurrPage();
                renderMbCurrPage();
                initPagination(specialData);
            }
        },
        'json'
    );
}

/**
 * 分页
 * http://pagination.js.org/index.html
 */
function initPagination(data) {
    $('#special-content__pagination').pagination({
        dataSource: data,
        totalNumber: data.length,
        pageSize: PAGE_SIZE,
        previous: '1',
        next: '1',
        afterPreviousOnClick: () => {
            changeNextPre('pre');
        },
        afterPageOnClick: (e) => {
            changePageNum(e);
        },
        afterNextOnClick: () => {
            changeNextPre('next');
        }
    });
}

/**
 * pc 返回顶部
 */
 function listenBack() {
    $('.special-content__back').off('click').on('click', function() {
        if (isAtBottom) {
            $('.special-content').animate({ scrollTop: 0 }, 1000);
        }
    });
}

/**
 * pc 入场动画
 */
 function addAnimation(time) {
    setTimeout(() => {
        $('.special-pc .special-header__left div:nth-child(1)').addClass(time ? 'textNoDelayEntry' : 'textEntry');
    }, time ? 0 : 100);
    setTimeout(() => {
        $('.special-pc .special-header__left div:nth-child(2)').addClass(time ? 'textNoDelayEntry' : 'textEntry');
        $('.special-pc .special-header__right').addClass('videoEntry');
    }, time ? 0 : 300);
    setTimeout(() => {
        $('.special-pc .special-header__left div:nth-child(3)').addClass(time ? 'textNoDelayEntry' : 'textEntry');
    }, time ? 0 : 500);
}

/**
 * pc select 样式兼容
 */
 function selectStyle() {
    const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    if (isSafari) {
        $('.special-pc .special-content__select').addClass('safari');
    }
}

/**
 * mobile click menu
 */
 function clickMenu() {
    $('.special-banner__top__menu').off('click').on('click', function() {
       $('side').fadeIn();
    });
}

/**
 * mobile click back top
 */

function clickBackTop() {
    $('.special-m-footer__backtop').off('click').on('click', function() {
        $('.mobile').animate({ scrollTop: 0 }, 600);
    });
}

function loadMore() {
    $('.special-m-more').off('click').on('click', function() {
        mbPageNum += 1;
        renderMbCurrPage();
    });
}

export function init () {
    $(function() {
        //pc
        listenScroll();
        listenBack();
        addAnimation();
        fetchPeriodicalData();
        selectStyle();
        //mobile
        clickMenu();
        listenMbScroll();
        clickBackTop();
        loadMore();
    });
}

// init();