import $ from 'jquery';
import { pcRouterMap, mbRouterMap, getUrlParams, updateUrl, loadHtmlPath, isMobile } from './utlis';
import '../styles/reset.scss';
import '../styles/index.scss';
import '../styles/view.scss';
import '../styles/cutto.scss';


$(function() {
    // 苹果登录 分割符为#
    const splitChat = window.location.href.includes('state=apple') ? '#' : '?';
    const { p, state, code, oauth_token, oauth_verifier } = getUrlParams(splitChat);
    const pageName = $(window).width() > 1240 ? pcRouterMap[p] : mbRouterMap[p];
    if ((state && code) || (oauth_token && oauth_verifier)) {
        let source = '';
        // 避免返回的params截取后带有其他未知的符号，重新匹配获取
        if (state && code) {
            if (state.includes('gmail')) {
                source = 'gmail';
            } else if (state.includes('apple')) {
                source = 'apple';
                if (isMobile()) {
                    loginWithOther({ type: source, code });
                }
            } else if (state.includes('facebook')) {
                source = 'facebook';
            } else if (state.includes('line')) {
                source = 'line';
                loginWithOther({ type: source, code });
            }
            localStorage.setItem('otherLoginInfo', JSON.stringify({ source, code }));
        }
        // twitter 使用 oauth1.0a,和其他登录方式不一致
        if (oauth_token && oauth_verifier) {
            localStorage.setItem('otherLoginInfo', JSON.stringify({ source: 'twitter', oauth_token, oauth_verifier }));
        }
        // 登录后关闭窗口,source不为line或apple移动端时不关闭窗口
        if (source !== 'line') {
            if (source !== 'apple' || (source === 'apple' && !isMobile())) {
                setTimeout(() => {
                    window.close();
                }, 301);
            }
        }
    }


    $('side').load(`${loadHtmlPath}public/side.html`, () => {
        require('../styles/side.scss');
        require('../styles/mobile/side.scss');
        require('./side');
    });

    // $('cutto').load(`${loadHtmlPath}public/cutto.html`, () => {
    //     require('../styles/cutto.scss');
    //     require('./cutto');
    // });

    handleResize();

    if (pageName) {
        $('view').load(`${loadHtmlPath}public/${pageName}.html`, () => {
            require(`../styles/${pageName}.scss`);
            require(`../styles/mobile/${pageName}.scss`);
            const { init } = require(`./${pageName}.js`);
            init && init();
            updateUrl('p', pageName);
        });
        return;
    }

    $('view').load(`${loadHtmlPath}public/home.html`, () => {
        require('../styles/home.scss');
        require('../styles/mobile/home.scss');
        const { init } = require('./home');
        init && init();
    });

    function handleResize() {
        let isLoadPage = false;
        $(window).on('resize', function() {
            // 移动端没有periodical、guide页面，统一转首页
            const { p } = getUrlParams();
            const pcPage = pcRouterMap[p];
            const mbPage = mbRouterMap[p];
            // 修复安卓输入框撑起导致触发resize，导致闪烁的问题
            const inputFocusStorage = sessionStorage.getItem('inputFocusStorage');
            if (!pcPage || !mbPage || inputFocusStorage === 'true') {
                sessionStorage.removeItem('inputFocusStorage');
                return;
            }
            // pc -> mobile
            if (['periodical', 'guide'].includes(pcPage) && $(window).width() <= 1240) {
                isLoadPage = false;
                $('view').load(`${loadHtmlPath}public/home.html`, () => {
                    // 引入js
                    const { init, changeSubPage } = require('./home.js');
                    require('../styles/home.scss');
                    require('../styles/mobile/home.scss');
                    init && init();
                    changeSubPage && changeSubPage(pcPage === 'guide' ? '3' : '2', pcPage);
                });
                updateUrl('p', pcPage);
                return;
            }
            // mobile -> pc
            !isLoadPage && $('view').load(`${loadHtmlPath}public/${pcPage}.html`, () => {
                require(`../styles/${pcPage}.scss`);
                require(`../styles/mobile/${pcPage}.scss`);
                const { init } = require(`./${pcPage}.js`);
                init && init();
                updateUrl('p', pcPage);
                isLoadPage = true;
            });
        });
    }
    // 第三方登录, 目前只针对line做处理
    function loginWithOther(params) {
        // 三方账号登录
        const otherLoginUrl = process.env.IS_DEMO === 'true' ? 'https://test-ad.baidu.jp/opera/simeji-appui/passport/sectbindWeb' : 'https://api.simeji.me/opera/simeji-appui/passport/sectbindWeb';
        $.ajax({
            type: 'get',
            cache: false,
            data: params,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType: 'json',
            async: true,
            url: otherLoginUrl,
            success: function (res) {
                // 第三方登录成功 清除twitterSecret
                if (res && res.data && res.errno === 0) {
                    const { bduss, user_name, portrait, profile } = res.data;
                    const loginInfo = { bduss, user_name, portrait, profile, source: params.type };
                    sessionStorage.setItem('loginUserInfo', JSON.stringify(loginInfo));
                } else if (res && res.errno === 41) {
                    sessionStorage.setItem('loginUserError', 'true');
                }
                location.href = 'https://simeji.me/?p=account';
            }
        });
    }
});
