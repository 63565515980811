import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

const key = CryptoJS.enc.Utf8.parse('MKR5V4D2W3lt2kmP');
const iv = CryptoJS.enc.Utf8.parse('0123456789abcdef');
// 加密
export const encrypt = (word) => {
    // 加密向量16位
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(word)), key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    let endEncrypted = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return endEncrypted;
};

// 解密
export const decrypt = (data) => {
    const decryptedText = CryptoJS.AES.decrypt(data, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
    });
    const jsonString = CryptoJS.enc.Base64.stringify(decryptedText);
    return Base64.decode(jsonString);
};

export const MD5 = (word) =>  CryptoJS.MD5(word).toString();
