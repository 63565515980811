import $ from 'jquery';


/**
 * pc 处理scroll动画
 */
 function listenScroll () {
    $('.recruit-content').off('scroll').on('scroll', function() {
        // 返回顶部
        const { scrollTop } = $(this)[0];
        // 头部伸缩
        if (scrollTop > 60) {
            $('.recruit-header').addClass('scrollTop');
            $(this).addClass('scrollHeight');
            return;
        }
        $('.recruit-header').removeClass('scrollTop');
        $(this).removeClass('scrollHeight');
    });
}

/**
 * pc recruit 详情显示关闭
 */
 function listenOpen() {
    $('div.recruit-header__left__btn').each(function() {
        $(this).off('click').on('click', function() {
            window.open('https://docs.google.com/forms/d/e/1FAIpQLSe1UXfKxY7VMCTpcCfhAptsMZo5dtifJCy_ziWHZIl0zfjgfQ/viewform?usp=sf_link', '_blank');
        });
    });
}
/**
 * pc recruit 点击图片跳转
 */
function listenImgOne(index, url) {
    $(`div.recruit-content__type__item :nth-child(${index})`).each(function() {
        $(this).off('click').on('click', function() {
            $('div.recruit-page .recruit-page__header__title').text('採用情報RECRUIT');
            $('div.recruit-page').css('width','95%');
            $('div.recruit-page .recruit-page__content').attr('src', url);
            addMask();
            $('div.recruit-page').addClass('showPage');
        });
    });
    $(`div.recruit-m-content__type__item :nth-child(${index})`).each(function() {
        $(this).off('click').on('click', function() {
            window.open(url, '_blank');
        });
    });
}

function initializeClickHandlers() {
    listenImgOne(1, 'https://simeji.me/cv-details/cv-details-pm.html');
    listenImgOne(2, 'https://simeji.me/cv-details/cv-details-ad.html');
    listenImgOne(3, 'https://simeji.me/cv-details/cv-details-inter.html');
    listenImgOne(4, 'https://simeji.me/cv-details/cv-details-adver.html');
    listenImgOne(5, 'https://simeji.me/cv-details/cv-details-ux.html');
    listenImgOne(6, 'https://simeji.me/cv-details/cv-details-pr.html');
}

/**
 * pc recruit 点击字体跳转
 */
function listenFontOne(index, url) {
    $(`div.recruit-content__works :nth-child(${index})`).each(function() {
        $(this).off('click').on('click', function() {
            $('div.recruit-page .recruit-page__header__title').text('WORKS/業務実績');
            $('div.recruit-page').css('width','95%');
            $('div.recruit-page .recruit-page__content').attr('src', url);
            addMask();
            $('div.recruit-page').addClass('showPage');
        });
    });
    $(`div.recruit-m-content__works :nth-child(${index})`).each(function() {
        $(this).off('click').on('click', function() {
            window.open(url, '_blank');
        });
    });
}

function initializeClic() {
    listenFontOne(2, 'https://simeji.me/blog/cp/simeji_sanrio');
    listenFontOne(3, 'https://simeji.me/blog/news/simeji-23518/id=23518');
    listenFontOne(4, 'https://simeji.me/blog/news/simeji-23492/id=23492');
    listenFontOne(5, 'https://simeji.me/blog/news/simeji-23485/id=23485');
}



function listenClose() {
    $('p.recruit-page__header__close').off('click').on('click', function() {
        removeMask();
    });
}

/**
 * pc 入场动画
 */
 function addAnimation() {
    setTimeout(() => {
        $('.recruit-pc .recruit-header__left div:nth-child(1)').addClass('textEntry');
    }, 100);
    setTimeout(() => {
        $('.recruit-pc .recruit-header__left div:nth-child(2)').addClass('textEntry');
        $('.recruit-pc .recruit-header__right').addClass('videoEntry');
    }, 300);
    setTimeout(() => {
        $('.recruit-pc .recruit-header__left div:nth-child(3)').addClass('textEntry');
        $('.recruit-pc .recruit-header__left .recruit-header__left__btn').addClass('textEntry');
    }, 500);
}

/**
 * pc 遮罩层
 */
function addMask(){
    var newChild = $('<div/>', {
        'class': 'recruit-mask'
    });
    $('side').css('z-index',-1);
    $('.recruit-pc').after(newChild);
    $('.recruit-mask').off('click').on('click',function(){
        removeMask();
    });
}
/**
 * pc 遮罩层
 */
function removeMask(){
    $('div.recruit-page').removeClass('showPage');
    $('div.recruit-mask').remove();
    $('side').css('z-index',99);
}
/**
 * mobile click menu
 */
 function clickMenu() {
    $('.recruit-banner__top__menu').off('click').on('click', function() {
       $('side').fadeIn();
    });
}

export async function init() {
    // mobile
    clickMenu();

    // pc
    addAnimation();
    listenScroll();
    listenOpen();
    listenClose();
    initializeClickHandlers();
    initializeClic();
}

$(function() {
    init();
});
