import $ from 'jquery';
import { isDevelopment } from './utlis';
import './pagination.js';
import '../styles/pagination.css';

/**
 * 处理scroll动画
 */
$('.periodical-content').off('scroll').on('scroll', function() {
    const { scrollTop } = $(this)[0];
    // 头部伸缩
    if (scrollTop > 36) {
        $('.periodical-header').addClass('scrollTop');
        $(this).addClass('scrollHeight');
        return;
    }
    $('.periodical-header').removeClass('scrollTop');
    $(this).removeClass('scrollHeight');
});

/**
 * 获取渲染 periodical
 */
// eslint-disable-next-line no-unused-vars
function createPeriodical(data) {
    let periodicalItem = '';
    for (let index = 0; index < data.length; index++) {
        const currItem = data[index];
        periodicalItem += `<div class="periodical-content__card__item">
            <span>${currItem.time}</span>
            <span>${currItem.type}</span>
            <span>${currItem.content}</span>
        </div>`;
    }
    $('.periodical .periodical-content__card').append(periodicalItem);
}

// 当前页码
let pageNum = 1;
// pageSize
const PAGE_SIZE = 16;
// 时间类型
let timeType = 'all';
// 所有数据
let periodicalData = [];

/**
 * change year
 */
// eslint-disable-next-line no-unused-vars
function changeYear() {
    $('#periodical-select').off('change').on('change', function() {
        timeType = $(this).val();
        pageNum = 1;
        renderCurrPage(true);
    });
}

/**
 * 获取当前页面数据并渲染
 */
function renderCurrPage(isChangeYear = false) {
    // 年份条件限制下的总数据
    const allData = timeType === 'all'
        ? periodicalData
        : periodicalData.filter(item => item.time.indexOf(timeType) !== -1);
    // 当前页码数据
    const currData = allData.slice((pageNum - 1) * PAGE_SIZE, (pageNum - 1) * PAGE_SIZE + PAGE_SIZE);
    // 清空数据
    $('.periodical-content__card').empty();
    isDevelopment && console.log('currData', currData);
    // 渲染数据
    createPeriodical(currData);
    // 改变年份 && 年份不是all -- 销毁分页重新渲染
    (timeType !== 'all' && isChangeYear) && $('#periodical-content__pagination').empty();
    (timeType !== 'all' && isChangeYear && allData.length) && initPagination(allData);
}

// eslint-disable-next-line no-unused-vars
function changePageNum(e) {
    pageNum = parseInt(e.currentTarget.innerText, 10);
    renderCurrPage();
}

// eslint-disable-next-line no-unused-vars
function changeNextPre(type) {
    if(type === 'pre') {
        pageNum -= 1;
        renderCurrPage();
        return;
    }
    pageNum += 1;
    renderCurrPage();
}

// eslint-disable-next-line no-unused-vars
function fetchPeriodicalData () {
    $.get('https://api.simeji.me/simeji-appui/config/getstablev2', {
        conf_key: 'simeji.all.simejiwebsite',
    },
        (res) => {
            isDevelopment && console.log(res);
            if (res.errno === 0) {
                periodicalData = res.data.list;
                renderCurrPage();
                initPagination(res.data.list);
            }
        },
        'json'
    );
}

/**
 * 分页
 * http://pagination.js.org/index.html
 */
function initPagination(data) {
    $('#periodical-content__pagination').pagination({
        dataSource: data,
        totalNumber: data.length,
        pageSize: PAGE_SIZE,
        previous: '1',
        next: '1',
        afterPreviousOnClick: () => {
            changeNextPre('pre');
        },
        afterPageOnClick: (e) => {
            changePageNum(e);
        },
        afterNextOnClick: () => {
            changeNextPre('next');
        }
    });
}

/**
 * pc 入场动画
 */
function addAnimation() {
    setTimeout(() => {
        $('.periodical-header__left div:nth-child(1)').addClass('textEntry');
    }, 100);
    setTimeout(() => {
        $('.periodical-header__left div:nth-child(2)').addClass('textEntry');
    }, 300);
    setTimeout(() => {
        $('.periodical-header__left div:nth-child(3)').addClass('textEntry');
    }, 500);
}

/**
 * pc select 样式兼容
 */
function selectStyle() {
    const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    if (isSafari) {
        $('.periodical-content__select').addClass('safari');
    }
}

export function init () {
    $(function() {
        addAnimation();
        fetchPeriodicalData();
        changeYear();
        selectStyle();
    });
}

// init();
