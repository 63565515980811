export const isDevelopment = process.env.NODE_ENV === 'development';
export const isDemo = process.env.IS_DEMO === 'true';

const demoOrProd = isDemo
    ? 'https://simeji.me/demo/'
    : 'https://d1yon1ba9a2ouz.cloudfront.net/simeji-website/';

export const loadHtmlPath = isDevelopment ? '../../' : demoOrProd;

export const pcRouterMap = {
    home: 'home',
    news: 'news',
    guide: 'guide',
    periodical: 'periodical',
    vip: 'vip',
    faq: 'faq',
    recruit: 'recruit',
    special: 'special',
    account: 'account'
};

export const mbRouterMap = {
    home: 'home',
    news: 'news',
    guide: 'home',
    periodical: 'home',
    vip: 'vip',
    faq: 'faq',
    recruit: 'recruit',
    special: 'special',
    account: 'account'
};

export const pcIdxMap = {
    home: '1',
    news: '2',
    periodical: '3',
    guide: '4',
    vip: '5',
    faq: '6',
    recruit: '',
    special: '8',
    account: '9'
};

export const mbIdxMap = {
    home: '1',
    news: '2',
    periodical: '3',
    guide: '4',
    vip: '5',
    faq: '6',
    recruit: '7',
    special: '8',
    account: '9',
    ad: '10'
};

export const adUrl = 'https://www.baidu.jp/contact/';

export function updateUrl(key, value) {
    const newurl = updateQueryStringParameter(key, value);
    //向当前url添加参数，没有历史记录
    window.history.replaceState({ path: newurl }, '', newurl);
}

function updateQueryStringParameter(key, value) {
    let uri = window.location.href;
    if (!value) {
        return uri;
    }
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        return uri + separator + key + '=' + value;
    }
}

export function isIOS() {
    return (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent));
}
/**
 * 判断当前设备是否是移动端
 */
export const isMobile = () => {
    let ua = navigator.userAgent.toLowerCase();
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        return true;
    } else if (ua.match(/macintosh/i) && ua.match(/apple/i)) {
        // iphone safari 打开桌面网站的情况
        let deviceWidth = screen.width;
        if (deviceWidth <= 750) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export function getUrlParams(splitChat = '?') {
    const url = window.location.href;
    // 通过 ? 分割
    let urlStr = url.split(splitChat)[1];
    // 创建空对象存储参数
    let obj = {};
    // 通过 & 分割
    let paramsArr = urlStr?.split('&') || '';
    for (let i = 0, len = paramsArr.length; i < len; i++) {
        // key:value
        let arr = paramsArr[i].split('=');
        obj[arr[0]] = arr[1];
    }
    return obj;
}

export function throttle(fn, delay) {
    let valid = true;
    return function() {
        if (!valid) {
            return false;
        }

        valid = true;
        setTimeout(() => {
            fn();
            valid = false;
        }, delay);
    };
}

export function getPixelRatio(context) {
    const backingStore = context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio || 1;
    return (window.devicePixelRatio || 1) / backingStore;
}
