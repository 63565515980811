import $ from 'jquery';

export function init() {
    $('.cutto-video').remove();

    // const imgUrl = `https://d25agdru0x4p6n.cloudfront.net/simeji-website/cutto.png?t=${Date.now()}`;
    const imgUrl = require('../images/cutto.png');
    const imgLoad = new Image();
    imgLoad.src = `${imgUrl}?t=${Date.now()}`;
    imgLoad.className = 'cutto-video';
    imgLoad.id = 'cutto-video';
    imgLoad.onload = function() {
        $('.cutto').append(imgLoad);
    };
}
