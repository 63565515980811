import $ from 'jquery';
import './pagination.js';
import '../styles/pagination.css';
import { isDevelopment } from './utlis';
import ClipboardJS from 'clipboard';

/**
 * pc 处理scroll动画
 */
let isAtBottom = false;
function listenScroll() {
    $('.news-content').off('scroll').on('scroll', function() {
        if (!$(this)[0]) {
            return;
        }
        // 返回顶部
        const { scrollHeight, scrollTop } = $(this)[0];
        const { height: domHeight } = $(this)[0].getBoundingClientRect();
        if (scrollTop + domHeight >= scrollHeight - 10) {
            $('.news-content__back').addClass('showBack');
            isAtBottom = true;
        } else {
            $('.news-content__back').removeClass('showBack');
            isAtBottom = false;
        }

        // 头部伸缩
        if (scrollTop > 186) {
            $('.news-header').addClass('scrollTop');
            $(this).addClass('scrollHeight');
            return;
        }
        $('.news-header').removeClass('scrollTop');
        $(this).removeClass('scrollHeight');
    });
}

/**
 * pc 处理type点击
 */
let currTypeIdx = 'all';
// 选中态
const changeTypeStyle = () => {
    $('span.news-content__select__type__item').each(function() {
        const idx = this.getAttribute('index');
        if (currTypeIdx === idx) {
            $(this).addClass('currType');
        } else {
            $(this).removeClass('currType');
        }
    });
};
function listenType() {
    $('span.news-content__select__type__item').each(function() {
        $(this).off('click').on('click', function() {
            currTypeIdx = this.getAttribute('index');
            changeTypeStyle();
            pageNum = 1;
            renderCurrPage(true, true);
        });
    });
}

/**
 * pc 获取渲染news
 */

function createHtml(data) {
    let cardHtml = '';
    for (let index = 0; index < data.length; index++) {
        const currItem = data[index];
        cardHtml += `<div class="news-content__card__item" pageLink="${currItem.link}" pageTitle="${currItem.title}" pageIndex="${index}">
            <img class="news-content__card__item__img" src="${currItem.img}" draggable="false" alt="" srcset="">
            <div class="news-content__card__item__text">
                <p>${currItem.time}</p>
                <p>${currItem.title}</p>
                <p>${currItem.desc}</p>
            </div>
        </div>`;
    }
    $('.news-pc .news-content__card').append(cardHtml);
    bindNewsFunc();
}

/**
 * mobile 获取渲染news
 */

function createMbHtml(data) {
    let cardHtml = '';
    for (let index = 0; index < data.length; index++) {
        const currItem = data[index];
        cardHtml += `<a class="news-m-content__item" href="${currItem.link}" target="_blank" rel="noopener noreferrer">
            <img class="news-m-content__item__img" src="${currItem.img}" alt="" srcset="">
            <div class="news-m-content__item__text">
                <p>${currItem.time}</p>
                <p>${currItem.title}</p>
                <p>${currItem.desc}</p>
            </div>
        </a>`;
    }
    $('.news-m-content').append(cardHtml);
}

/**
 * pc 获取数据并渲染
 */
// 当前页码
let pageNum = 1;
let mbPageNum = 1;
// pageSize
const PAGE_SIZE = 16;
// 时间类型
let timeType = 'all';
let mbTimeType = 'all';
// 所有数据
let newsData = [];
// 当前渲染数据
let currData = [];
let mbCurrData = [];

// eslint-disable-next-line no-unused-vars
function fetchNewsData() {
    $.get('https://api.simeji.me/simeji-appui/config/getstablev2', {
        conf_key: 'simeji.all.simejiwebsite-news',
    },
        (res) => {
            isDevelopment && console.log(res);
            if (res.errno === 0) {
                newsData = res.data.list;
                renderCurrPage();
                renderMbCurrPage();
                initPagination(res.data.list);
            }
        },
        'json'
    );
}

/**
 * pc 获取当前页面数据并渲染
 */
function renderCurrPage(isChangeYear = false, isChangeType = false) {
    // 年份、类型条件限制下的总数据
    let allData = timeType === 'all'
        ? newsData
        : newsData
            .filter(item => item.time.indexOf(timeType) !== -1);
    allData = currTypeIdx === 'all'
        ? allData
        : allData.filter(item => item.type === parseInt(currTypeIdx, 10));
    // 当前页码数据
    currData = allData.slice((pageNum - 1) * PAGE_SIZE, (pageNum - 1) * PAGE_SIZE + PAGE_SIZE);
    // 清空数据
    $('.news-pc .news-content__card').empty();
    isDevelopment && console.log('currData', currData, allData);
    // 渲染数据
    createHtml(currData);
    // 改变年份 || 改变年份 && 年份不是all -- 销毁分页重新渲染
    ((timeType !== 'all' || isChangeType) && isChangeYear) && $('#news-content__pagination').empty();
    ((timeType !== 'all' || isChangeType) && isChangeYear) && initPagination(allData);
}

/**
 * mobile 获取当前页面数据并渲染
 */
function renderMbCurrPage() {
    // 年份、类型条件限制下的总数据
    let allData = mbTimeType === 'all'
        ? newsData
        : newsData
            .filter(item => item.time.indexOf(mbTimeType) !== -1);
    allData = currMTypeIdx === 'all'
        ? allData
        : allData.filter(item => item.type === parseInt(currMTypeIdx, 10));
    // 当前页码数据
    mbCurrData = allData.slice((mbPageNum - 1) * PAGE_SIZE, (mbPageNum - 1) * PAGE_SIZE + PAGE_SIZE);
    // 清空数据
    isDevelopment && console.log('mbCurrData', mbCurrData, allData);
    // 渲染数据
    createMbHtml(mbCurrData);
}

/**
 * change year
 */

function changeYear() {
    $('#news-select').off('change').on('change', function() {
        timeType = $(this).val();
        pageNum = 1;
        renderCurrPage(true);
    });
    $('#news-m-select__year').off('change').on('change', function() {
        mbTimeType = $(this).val();
        mbPageNum = 1;
        $('.news-m-content').empty();
        renderMbCurrPage();
    });
}

function changePageNum(e) {
    pageNum = parseInt(e.currentTarget.innerText, 10);
    renderCurrPage();
}

function changeNextPre(type) {
    if(type === 'pre') {
        pageNum -= 1;
        renderCurrPage();
        return;
    }
    pageNum += 1;
    renderCurrPage();
}


/**
 * pc news 详情显示关闭
 */
let pageIndex = 0;
function bindNewsFunc() {
    pageIndex = 0;
    $('.news-pc .news-content__card__item').each(function() {
        $(this).off('click').on('click', function() {
            const pageLink = this.getAttribute('pageLink');
            const pageTitle = this.getAttribute('pageTitle');
            pageIndex = parseInt(this.getAttribute('pageIndex'), 10);
            const pageHtml = `<div class="news-page__header">
                <p class="news-page__header__close"></p>
                <p class="news-page__header__title">${pageTitle}</p>
                <div class="news-page__header__opt">
                    <p class="news-page__header__copyLink" url_link=${pageLink}></p>
                    <p class="news-page__header__opt__pre"></p>
                    <p class="news-page__header__opt__next"></p>
                </div>
            </div>
            <iframe class="news-page__content" src="${pageLink}" frameborder="0"></iframe>`;
            $('div.news-page').empty();
            $('div.news-page').append(pageHtml);
            $('div.news-page').addClass('showPage');
            $('.news-page__header__copyLink').off('click').on('click', shareContent);
            listenClose();
            perAndNext();
        });
    });
}

function listenClose() {
    $('p.news-page__header__close').off('click').on('click', function() {
        $('div.news-page').removeClass('showPage');
    });
}

/**
 * pc 显示页上一页下一页
 */
function perAndNext() {
    // 样式
    pageIndex !== 0
        ? $('.news-page__header__opt__pre').removeClass('disable')
        : $('.news-page__header__opt__pre').addClass('disable');

    pageIndex !== currData.length - 1
        ? $('.news-page__header__opt__next').removeClass('disable')
        : $('.news-page__header__opt__next').addClass('disable');

    // 数据 & dom
    $('.news-page__header__opt__pre').off('click').on('click', function() {
        if (pageIndex === 0) {
            return;
        }
        pageIndex = parseInt(pageIndex, 10) - 1;
        const { link, title } = currData[pageIndex];
        $('.news-page__header__title').text(title);
        $('.news-page__content').attr('src', link);
        pageIndex !== 0
            ? $('.news-page__header__opt__pre').removeClass('disable')
            : $('.news-page__header__opt__pre').addClass('disable');
        pageIndex !== currData.length - 1
            ? $('.news-page__header__opt__next').removeClass('disable')
            : $('.news-page__header__opt__next').addClass('disable');
    });
    $('.news-page__header__opt__next').off('click').on('click', function() {
        if (pageIndex === currData.length - 1) {
            return;
        }
        pageIndex = parseInt(pageIndex, 10) + 1;
        const { link, title } = currData[pageIndex];
        $('.news-page__header__title').text(title);
        $('.news-page__content').attr('src', link);
        pageIndex !== 0
            ? $('.news-page__header__opt__pre').removeClass('disable')
            : $('.news-page__header__opt__pre').addClass('disable');
        pageIndex !== currData.length - 1
            ? $('.news-page__header__opt__next').removeClass('disable')
            : $('.news-page__header__opt__next').addClass('disable');
    });
}

/**
 * pc 分页
 * http://pagination.js.org/index.html
 */
function initPagination(data) {
    data.length && $('#news-content__pagination').pagination({
        dataSource: data,
        totalNumber: data.length,
        pageSize: PAGE_SIZE,
        previous: '1',
        next: '1',
        afterPreviousOnClick: () => {
            changeNextPre('pre');
        },
        afterPageOnClick: (e) => {
            changePageNum(e);
        },
        afterNextOnClick: () => {
            changeNextPre('next');
        }
    });
}

/**
 * pc 返回顶部
 */
function listenBack() {
    $('.news-content__back').off('click').on('click', function() {
        if (isAtBottom) {
            $('.news-content').animate({ scrollTop: 0 }, 1000);
        }
    });
}

/**
 * pc 入场动画
 */
function addAnimation(time) {
    setTimeout(() => {
        $('.news-pc .news-header__left div:nth-child(1)').addClass(time ? 'textNoDelayEntry' : 'textEntry');
    }, time ? 0 : 100);
    setTimeout(() => {
        $('.news-pc .news-header__left div:nth-child(2)').addClass(time ? 'textNoDelayEntry' : 'textEntry');
        $('.news-pc .news-header__right').addClass('videoEntry');
    }, time ? 0 : 300);
    setTimeout(() => {
        $('.news-pc .news-header__left div:nth-child(3)').addClass(time ? 'textNoDelayEntry' : 'textEntry');
    }, time ? 0 : 500);
}

/**
 * pc select 样式兼容
 */
function selectStyle() {
    const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    if (isSafari) {
        $('.news-pc .news-content__select').addClass('safari');
    }
}

/**
 * mobile 处理type点击
 */
let currMTypeIdx = 'all';
// 选中态
const changeMbTypeStyle = () => {
    $('p.news-m-type__item').each(function() {
        const idx = this.getAttribute('index');
        if (currMTypeIdx === idx) {
            $(this).addClass('currType');
        } else {
            $(this).removeClass('currType');
        }
    });
};
function listenMbType () {
    $('p.news-m-type__item').each(function() {
        $(this).off('click').on('click', function() {
            currMTypeIdx = this.getAttribute('index');
            changeMbTypeStyle();
            $('.news-m-content').empty();
            renderMbCurrPage();
        });
    });
}

/**
 * mobile 处理scroll动画
 */
function listenMbScroll() {
    $('.news-mobile').off('scroll').on('scroll', function() {
        if (!$(this)[0]) {
            return;
        }
        // 头部伸缩
        const { scrollTop } = $(this)[0];
        if (scrollTop > 30) {
            $('.news-banner__bottom__text').fadeOut('fast');
            $('.news-banner').addClass('scrollTop');
            return;
        }
        $('.news-banner__bottom__text').fadeIn('fast');
        $('.news-banner').removeClass('scrollTop');
    });
}

/**
 * mobile click menu
 */
function clickMenu() {
    $('.news-banner__top__menu').off('click').on('click', function() {
       $('side').fadeIn();
    });
}

/**
 * mobile click back top
 */

function clickBackTop() {
    $('.news-m-footer__backtop').off('click').on('click', function() {
        $('.mobile').animate({ scrollTop: 0 }, 600);
    });
}

function loadMore() {
    $('.news-m-more').off('click').on('click', function() {
        mbPageNum += 1;
        renderMbCurrPage();
    });
}

/**分享 */
function shareContent(e) {
    let className ='.' + $(e.target).attr('class');
    let url = $(e.target).attr('url_link');
    //解决重复的回调
    $(className).off('click');

    const clipboardJS = new ClipboardJS(className, {
        // 点击copy按钮，直接通过text直接返回复印的内容
            text: function() {
                return `${url}`;
            }
        });
    //成功的回调
    clipboardJS.on('success', function() {
        createTooltips('リンクをコピーしました');
    });
    //失败的回调
    clipboardJS.on('error', function(e) {
        console.log(e);
    });
}

const createTooltips = throttle(createTooltip, 200);

function createTooltip(content) {
    const tooltipHtml = `<div class="news-page__header__tooltip">
        <p class="news-page__header__tooltip__icons"></p>
        <p class="news-page__header__tooltip__content">${content}</p>
    </div>`;
    $('.news-page__header').append(tooltipHtml);
    setTimeout(function() {
        $('.news-page__header__tooltip').remove();
    }, 1000);
}

/**节流 */
function throttle(func, delay) {
    let timerId;
    return function (...args) {
      if (timerId) {
        return;
      }
      timerId = setTimeout(() => {
        func.apply(this, args);
        timerId = undefined;
      }, delay);
    };
}

export async function init(time) {
    $(function() {
        // mobile
        listenMbType();
        changeMbTypeStyle();
        listenMbScroll();
        clickMenu();
        clickBackTop();
        loadMore();

        // pc
        selectStyle();
        addAnimation(time);
        changeTypeStyle();
        listenType();
        listenClose();
        listenBack();
        changeYear();
        fetchNewsData();
        listenScroll();
        bindNewsFunc();
    });
}

// init();
