import $ from 'jquery';
import 'swiper/css';
import Swiper, { Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import { isDevelopment, isIOS, updateUrl, loadHtmlPath } from './utlis';
import { toNewsPage } from './side';
// import * as cutto from './cutto';
import * as news from './news';
require('../styles/news.scss');
require('../styles/mobile/news.scss');

Swiper.use([Autoplay]);

// 初始化banner
function initSwiper() {
    new Swiper('#swiper', {
        slidesPerView: 'auto',
        spaceBetween: 4,
        speed: 3500, //滚动速度
        freeMode: true,
        loop: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: false,
            loopPreventsSlide: true,
        },
    });
}

// eslint-disable-next-line no-unused-vars
function getImgList() {
    const list = [];
    for (let index = 1; index < 48; index++) {
        list.push(require(`../images/home/canvas/home${index}.png`));
    }
    return list;
}

function initVideo() {
    const imgUrl = require('../images/home/bg.png');
    const imgLoad = new Image();
    imgLoad.src = imgUrl;
    imgLoad.onload = function() {
        $('.home-pc .home-video').css('background', 'unset');
        $('.home-pc .home-video').append(imgLoad);
    };
}
function initMobileVideo() {
    const imgUrl = require('../images/home/bg.png');
    const imgLoad = new Image();
    imgLoad.src = imgUrl;
    imgLoad.onload = function() {
        $('.home-banner__video').css('background', 'unset');
        $('.home-banner__video').append(imgLoad);
    };
}

/**
 * pc 入场动画
 */
function entryAnimation() {
    setTimeout(() => {
        $('.home-pc .home-title div:nth-child(1)').addClass('textEntry');
        $('.home-pc .home-video').addClass('videoEntry');
    }, 100);
    setTimeout(() => {
        $('.home-pc .home-title div:nth-child(2)').addClass('textEntry');
        $('.home-pc .home-blog').addClass('blogEntry');
        $('.home-pc .home-news').addClass('newsEntry');
    }, 300);
    setTimeout(() => {
        $('.home-pc .home-title div:nth-child(3)').addClass('textEntry');
    }, 500);
    setTimeout(() => {
        $('.home-pc .home-title div:nth-child(4)').addClass('textEntry');
    }, 600);
}

/**
 * pc 退场动画
 */
function exitAnimation() {
    $('.home-pc .home-title div:nth-child(1)').removeClass('textEntry').addClass('textExit');
    $('.home-pc .home-title div:nth-child(2)').removeClass('textEntry').addClass('textExit');
    $('.home-pc .home-title div:nth-child(3)').removeClass('textEntry').addClass('textExit');
    $('.home-pc .home-title div:nth-child(4)').removeClass('textEntry').addClass('textExit');
    $('.home-pc .home-video').removeClass('videoEntry').addClass('videoExit');
    $('.home-pc .home-blog').removeClass('blogEntry').addClass('blogExit');
    $('.home-pc .home-news').removeClass('newsEntry').addClass('newsExit');
}

// eslint-disable-next-line no-unused-vars
function resetAnimation() {
    setTimeout(() => {
        $('.cutto-header__left div:nth-child(1)').removeClass('textEntry');
        $('.cutto-header__left div:nth-child(2)').removeClass('textEntry');
        $('.cutto-header__left div:nth-child(3)').removeClass('textEntry');
        $('.cutto-mask').removeClass('newHight');
        const imgUrl = require('../images/cutto.png');
        const fixImgUrl = `${imgUrl}?t=${Date.now()}`;
        const imgLoad = new Image();
        imgLoad.src = fixImgUrl;
        imgLoad.onload = function() {
            $('.cutto-video').css('background', `url(${fixImgUrl}) no-repeat center center / 100% 100%`);
        };
    }, 100);
}

/**
 * pc 监听滚动条并转场
 */
window.newsHtml = null;
// eslint-disable-next-line no-unused-vars
function loadNewsHtml() {
    const news = document.createElement('div');
    $(news).load(`${loadHtmlPath}public/news.html`, (res) => {
        window.newsHtml = window.newsHtml ? window.newsHtml : res;
    });
}

// eslint-disable-next-line no-unused-vars
function listenScroll() {
    $('.home-pc').off('scroll').on('scroll', function() {
        const { scrollTop, scrollHeight, clientHeight } = $(this)[0];
        if (scrollTop + clientHeight === scrollHeight) {
            // scroll 0 无滚动条
            $('.home-scroll').css({ height: 0});
            // cutto 入场
            $('cutto').animate({ opacity: 1 }, 0);
            $('cutto').fadeIn();
            // home 页面元素退场
            exitAnimation();

            // news 元素入场动画
            setTimeout(() => {
                $('.cutto-header__left div:nth-child(1)').addClass('textEntry');
            }, 1000);
            setTimeout(() => {
                $('.cutto-mask').addClass('newHight');
                $('.cutto-header__left div:nth-child(2)').addClass('textEntry');
            }, 1200);
            setTimeout(() => {
                $('.cutto-header__left div:nth-child(3)').addClass('textEntry');
            }, 1300);
            setTimeout(() => {
                $('view').empty();
                $('view').append(window.newsHtml);
                // // 引入js
                news.init(true);
                updateUrl('p', 'news');
                toNewsPage();
                $('view').css({ display: 'unset' });
                $('cutto').animate({ opacity: 0 }, 1000, function() {
                    $('cutto').css({ display: 'none' });
                });
            }, 1400);
        }
    });
}

// 生成news dom
function createPeriodical(newData) {
    // 取前三数据
    let newsDom = '';
    for (let index = 0; index < 3; index++) {
        newsDom += `<div class="home-news__content__item" url_link=${newData[index].link}>
            <p>${newData[index].title}</p>
            <p>${newData[index].desc}</p>
        </div>`;
    }
    $('.home-news__content').append(newsDom);
    $('.home-news__content__item').each(function() {
        $(this).find('p:eq(1)').off('click').on('click', function() {
        let url = $(this).parent().attr('url_link');
          window.location.href = url;
        });
    });
}

function fetchPeriodical(pageNum = 1) {
    $.get('https://api.simeji.me/simeji-appui/config/getstablev2', {
        conf_key: 'simeji.all.simejiwebsite',
    },
        (res) => {
            isDevelopment && console.log(res);
            if (res.errno === 0) {
                // mobile
                renderPeriodical(res.data.list, pageNum);
            }
        },
        'json'
    );
}

// 生成banner dom
function createBanner(data) {
    let swiperSlide = '';
    for (let index = 0; index < data.length; index++) {
        let url_link = data[index].blog_url;
        if (data[index].id !== 1206 && url_link) {
            swiperSlide += `<img class="swiper-slide" src="${data[index].banner}" url_link=${url_link} alt="" srcset="">`;
        }
    }
    // if(!swiperSlide) return;
    $('#swiper .swiper-wrapper').append(swiperSlide);
    $('.swiper-wrapper').off('click').on('click', '.swiper-slide', function() {
        let url = $(this).attr('url_link');
        window.location.href = url;
      });
    initSwiper();
}

// 获取banner数据
// eslint-disable-next-line no-unused-vars
function fetchBanner() {
    $.get('https://api.simeji.me/simeji-skins/ios/getBannerList', {
        app_version: '50.5',
        bduss: '',
        device: 'ios',
        device_type: '3',
        is_ipad: '0',
        is_rn: '1',
        position: '0',
        sim_location: 'jp',
        sys_lang: 'en-JP',
        system_version: '14.2',
        uid: '5006499039',
        uuid: 'C1B80205-462F-4197-AA5C-1DDA1E4B03DD',
        wifi: '0',
    },
        (res) => {
            isDevelopment && console.log(res);
            if (res.errno === 0) {
                createBanner(res.data);
            }
        },
        'json'
    );
}

function fetchNewsData() {
    $.get('https://api.simeji.me/simeji-appui/config/getstablev2', {
        conf_key: 'simeji.all.simejiwebsite-news',
    },
        (res) => {
            if (res.errno === 0) {
                renderNews(res.data.list);
                //pc
                createPeriodical(res.data.list);
            }
        },
        'json'
    );
}

/**
 * mobile news dom
 */
function renderNews(data) {
    const newsData = data.slice(0, 16);
    let newsHtml = '';
    for (let index = 0; index < newsData.length; index++) {
        const currItem = newsData[index];
        newsHtml += `<div class="home-content__news__item" url_link=${currItem.link}>
            <img class="home-content__news__item__img" src="${currItem.img}" alt="" srcset="">
            <div class="home-content__news__item__text">
                <p>${currItem.time}</p>
                <p>${currItem.title}</p>
                <p>${currItem.desc}</p>
            </div>
        </div>`;
    }
    newsHtml += `<div class="home-content__news__more"  pageName="news">
        ニュース一覧
        <span></span>
    </div>`;
    $('.home-mobile .home-content__news').empty();
    $('.home-mobile .home-content__news').append(newsHtml);
    $('.home-content__news').off('click').on('click', '.home-content__news__item', function() {
        let url = $(this).attr('url_link');
        window.location.href = url;
    });
    clickMore();
}

/**
 * mobile periodical dom
 */
function renderPeriodical(data, pageNum) {
    const periodicalData = data.slice(0, 10 * pageNum);
    let periodicalHtml = '';
    for (let index = 0; index < periodicalData.length; index++) {
        const currItem = periodicalData[index];
        periodicalHtml += `<div class="home-content__periodical__item">
            <p>
                <span>${currItem.time}</span>
                <span>${currItem.type}</span>
            </p>
            <p>${currItem.content}</p>
        </div>`;
    }
    periodicalHtml += `<div class="home-content__periodical__more" pageName="periodical">
        ニュース一覧
        <span></span>
    </div>`;
    $('.home-mobile .home-content__periodical').empty();
    $('.home-mobile .home-content__periodical').append(periodicalHtml);
    clickMore();
}

/**
 * mobile click menu
 */
function clickMenu() {
    $('.home-banner__header__menu').off('click').on('click', function() {
       $('side').fadeIn();
    });
}

/**
 * mobile click get app
 */
function jumpAppPage() {
    if (isIOS()) {
        window.open('https://go.onelink.me/J2a5/rt1n0tfx', '_blank');
        return;
    }
    window.open('https://go.onelink.me/J2a5/2pj2tcet', '_blank');
}
function clickGetApp() {
    $('.home-banner__download').off('click').on('click', jumpAppPage);
    $('.home-content__guide__more__btn').off('click').on('click', jumpAppPage);
}

/**
 * mobile change tabs style
 * mobile listen click tabs
 * mobile cahgne tabs content html
 */
let tabIdx = '1';
let pageName = 'news';
function changeTabStyle() {
    $('span.home-tabs__item').each(function() {
        const idx = this.getAttribute('index');
        if (tabIdx === idx) {
            $(this).addClass('currItem');
        } else {
            $(this).removeClass('currItem');
        }
    });
}

function clickTabs() {
    $('span.home-tabs__item').each(function() {
        $(this).off('click').on('click', function() {
            $(`div.home-content__${pageName}`).fadeOut();
            tabIdx = this.getAttribute('index');
            pageName = this.getAttribute('pageName');
            changeTabStyle();
            updateUrl('p', pageName);
            setTimeout(() => {
                $(`div.home-content__${pageName}`).fadeIn(300).css('display', 'flex');
            }, 300);
        });
    });
}

/**
 * mobile nav jump to home
 */
export function changeSubPage(currTabIdx, currPageName) {
    tabIdx = currTabIdx;
    pageName = currPageName;
    changeTabStyle();
    $(`div.home-content__${pageName}`).fadeIn(300).css('display', 'flex');
    $('.home-mobile').animate({ scrollTop: 670 }, 600);
}

/**
 * mobile click more
 */

function loadPage(pageName) {
    $('view').load(`${loadHtmlPath}public/${pageName}.html`, (res) => {
        // 加载html
        $(this).html = res;
        // 引入js
        const { init } = require(`./${pageName}.js`);
        require(`../styles/${pageName}.scss`);
        require(`../styles/mobile/${pageName}.scss`);
        init && init();
    });
}

let periodicalNum = 1;
function clickMore() {
    $('.home-content__news__more').off('click').on('click', function() {
        const pageName = this.getAttribute('pageName');
        loadPage(pageName);
        updateUrl('p', 'news');
    });
    $('.home-content__periodical__more').off('click').on('click', function() {
        // const pageName = this.getAttribute('pageName');
        // loadPage(pageName);
        periodicalNum += 1;
        fetchPeriodical(periodicalNum);
    });
}

/**
 * mobile click back top
 */

function clickBackTop() {
    $('.home-footer__backtop').off('click').on('click', function() {
        $('.home').animate({ scrollTop: 0 }, 600);
    });
}

export function init() {
    $(function() {
        // resetAnimation();

        // mobile
        initMobileVideo();
        fetchNewsData();
        clickTabs();
        changeTabStyle();
        $(`div.home-content__${pageName}`).fadeIn(300).css('display', 'flex');
        clickMenu();
        clickGetApp();
        clickMore();
        clickBackTop();

        // pc
        entryAnimation();
        initVideo();
        fetchPeriodical();
        fetchBanner();
        listenScroll();
        loadNewsHtml();
    });
}

// init();
