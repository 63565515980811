import $ from 'jquery';

/**
 * 处理scroll动画
 */
function listenScroll() {
    $('.guide-content').off('scroll').on('scroll', function() {
        if (!$(this)[0]) {
            return;
        }
        const { scrollTop } = $(this)[0];

        // 头部伸缩
        if (scrollTop > 40) {
            $('.guide-header').addClass('scrollTop');
            $(this).addClass('scrollHeight');
            return;
        }
        $('.guide-header').removeClass('scrollTop');
        $(this).removeClass('scrollHeight');
    });
}

/**
 * pc 入场动画
 */
 function addAnimation() {
    setTimeout(() => {
        $('.guide-header__left div:nth-child(1)').addClass('textEntry');
    }, 100);
    setTimeout(() => {
        $('.guide-header__left div:nth-child(2)').addClass('textEntry');
        $('.guide-header__right').addClass('videoEntry');
    }, 300);
    setTimeout(() => {
        $('.guide-header__left div:nth-child(3)').addClass('textEntry');
    }, 500);
}

export function init() {
    addAnimation();
    listenScroll();
}

init();
