import $ from 'jquery';

/**
 * pc 处理scroll动画
 */
function listenScroll () {
    $('.vip-content').off('scroll').on('scroll', function() {
        // 返回顶部
        const { scrollTop } = $(this)[0];
        // 头部伸缩
        if (scrollTop > 60) {
            $('.vip-header').addClass('scrollTop');
            $(this).addClass('scrollHeight');
            return;
        }
        $('.vip-header').removeClass('scrollTop');
        $(this).removeClass('scrollHeight');
    });
}

/**
 * pc 入场动画
 */
function addAnimation() {
    setTimeout(() => {
        $('.vip-pc .vip-header__left div:nth-child(1)').addClass('textEntry');
    }, 100);
    setTimeout(() => {
        $('.vip-pc .vip-header__left div:nth-child(2)').addClass('textEntry');
        $('.vip-pc .vip-header__right').addClass('videoEntry');
    }, 300);
    setTimeout(() => {
        $('.vip-pc .vip-header__left div:nth-child(3)').addClass('textEntry');
        $('.vip-pc .vip-header__left .vip-header__left__btn').addClass('textEntry');
    }, 500);
}


/**
 * mobile 处理scroll动画
 */
function listenMbScroll() {
    $('.vip-mobile').off('scroll').on('scroll', function() {
        if (!$(this)[0]) {
            return;
        }
        // 头部伸缩
        const { scrollTop } = $(this)[0];
        if (scrollTop >= 30) {
            $('.vip-banner__bottom__text').fadeOut('fast');
            $('.vip-banner').addClass('scrollTop');
            return;
        }
        $('.vip-banner__bottom__text').fadeIn('fast');
        $('.vip-banner').removeClass('scrollTop');
    });
}

/**
 * mobile click menu
 */
function clickMenu() {
    $('.vip-banner__top__menu').off('click').on('click', function() {
       $('side').fadeIn();
    });
}

/**
 * mobile click back top
 */

function clickBackTop() {
    $('.vip-m-footer__backtop').off('click').on('click', function() {
        $('.mobile').animate({ scrollTop: 0 }, 600);
    });
}

export async function init() {
    $(function() {
        // mobile
        listenMbScroll();
        clickMenu();
        clickBackTop();

        //pc
        addAnimation();
        listenScroll();
    });
}

// init();
