import $ from 'jquery';
import { decrypt, encrypt, MD5 } from './crypto';
import { isIOS } from './utlis';
// 国家/区域list
const areasArray = [
    { label: '日本(Japan)：＋81', value: 'jp'},
    { label: '中国(China)：＋86', value: 'cn'},
    { label: '대한민국(Korea)：＋82', value: 'kr'},
    { label: 'Vietnam：＋84', value: 'vn'},
    { label: 'United States：＋1', value: 'us'},
    { label: 'Indonesia：＋62', value: 'in'},
    { label: 'Taiwan：＋886', value: 'tw'},
    { label: 'Russia：＋7', value: 'rs'},
    { label: 'Thailand：＋66', value: 'th'},
    { label: 'France：＋33', value: 'fr'},
    { label: 'Malaysia：＋60', value: 'ma'},
    { label: 'United Kingdom：＋44', value: 'uk'},
    { label: 'Philippines：＋63', value: 'ph'},
    { label: 'Australia：＋61', value: 'as'},
    { label: 'Germany：＋49', value: 'gm'},
    { label: 'Italy：＋39', value: 'it'},
    { label: 'Portugal：＋351', value: 'pt'},
    { label: 'Hongkong：＋852', value: 'hk'},
    { label: 'Canada：＋1', value: 'ca'},
    { label: 'Spain：＋34', value: 'sp'},
];
// 手机登录/注销
const mobileH5Url = process.env.IS_DEMO === 'true' ? 'https://test-ad.baidu.jp/passport/mobileH5' : 'https://api.simeji.me/passport/mobileH5';
// 三方账号注销
const otherAccountOutUrl = process.env.IS_DEMO === 'true' ? 'https://test-ad.baidu.jp/passport/user' : 'https://api.simeji.me/passport/user';
// 三方账号登录
const otherLoginUrl = process.env.IS_DEMO === 'true' ? 'https://test-ad.baidu.jp/opera/simeji-appui/passport/sectbindWeb' : 'https://api.simeji.me/opera/simeji-appui/passport/sectbindWeb';
// 获取twitter token
const getTwitterTokenUrl = process.env.IS_DEMO === 'true' ? 'https://test-ad.baidu.jp/opera/simeji-appui/passport/getTwitterToken' : 'https://api.simeji.me/opera/simeji-appui/passport/getTwitterToken';
/** 是否正在请求中 */
let isQuery = false;
/** twitter oauth_token_secret */
let twitterOauthTokenSecret = '';
let timer = null;

/**
 * pc 入场动画
 */
function addAnimation() {
    setTimeout(() => {
        $('.account-pc .account-header__left div:nth-child(1)').addClass('textEntry');
    }, 100);
    setTimeout(() => {
        $('.account-pc .account-header__left div:nth-child(2)').addClass('textEntry');
    }, 300);
    setTimeout(() => {
        $('.account-pc .account-header__left div:nth-child(3)').addClass('textEntry');
    }, 500);
}

/**
 * pc 处理scroll动画
 */
function listenScroll() {
    $('.account-content').off('scroll').on('scroll', function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (!$(this)[0]) {
                return;
            }
            // 返回顶部
            const { scrollTop } = $(this)[0];

            // 头部伸缩
            if (scrollTop > 10) {
                $('.account-header').addClass('scrollTop');
                $(this).addClass('scrollHeight');
                return;
            }
            if (scrollTop >0 && scrollTop < 10) { // 解决无法滚动问题
                $('.account-header').removeClass('scrollTop');
                $(this).removeClass('scrollHeight');
            }
        }, 100);
    });
}
/**
 * 添加下拉选项
 */
function insertSelect(selected) {
    const dropdownEl = $('#account-country-select-dropdown');
    if (dropdownEl) {
        let appendStr = `<div data-value="${selected}" class="account-content-row-select-dropdown-item selected">
            ${selected}
            <span class="account-content-row-select-dropdown-item-arrow-up"></span>
        </div>
        <div class="account-content-row-select-dropdown-options">`;
        areasArray.forEach(area => {
            if (area.label !== selected) {
                appendStr += `<div data-value="${area.label}" class="account-content-row-select-dropdown-item">${area.label}</div>`;
            }
        });
        appendStr += `</div>`;
        dropdownEl.html(appendStr);
    }
}
/**
 * 移动端添加下拉选项
 */
function insertMbSelect(selected) {
    const dropdownEl = $('#account-m-country-select-dropdown');
    if (dropdownEl) {
        let appendStr = `<div data-value="${selected}" class="account-m-content-box-select-dropdown-item selected">
            ${selected}
            <span class="account-m-content-box-select-dropdown-item-arrow-up"></span>
        </div>
        <div class="account-m-content-box-select-dropdown-options">`;
        areasArray.forEach(area => {
            if (area.label !== selected) {
                appendStr += `<div data-value="${area.label}" class="account-m-content-box-select-dropdown-item">${area.label}</div>`;
            }
        });
        appendStr += `</div>`;
        dropdownEl.html(appendStr);
    }
}
/** 国家下拉选项监听 */
function listenSelect() {
    $('#account-country-select').val(areasArray[0].label).off('click').on('click', function (event) {
        $('#account-country-select-dropdown').css({ display: 'block' });
        $('.account-content-row-top-error').css({ display: 'none' });
        event.stopImmediatePropagation();
    });
    $('#account-m-country-select').val(areasArray[0].label).off('click').on('click', function (event) {
        $('#account-m-country-select-dropdown').css({ display: 'block' });
        $('.account-m-content-box-top-error').css({ display: 'none' });
        event.stopImmediatePropagation();
    });
    $(document).off('click').on('click', function (e) {
        const dropdownEl = $('#account-country-select-dropdown');
        const mobileDropdownEl = $('#account-m-country-select-dropdown');
        const display = dropdownEl ? dropdownEl.css('display') : '';
        const mobileDisplay = mobileDropdownEl ? mobileDropdownEl.css('display') : '';
        if (e.target && (display === 'block' || mobileDisplay === 'block')) {
            const targetClassName = e.target.className;
            const value = e.target.dataset.value;
            if (value && targetClassName && ['account-content-row-select-dropdown-item','account-m-content-box-select-dropdown-item'].includes(targetClassName)) {
                $('#account-country-select').val(value);
                $('#account-m-country-select').val(value);
                insertSelect(value);
                insertMbSelect(value);
            }
            dropdownEl.css({ display: 'none' });
            mobileDropdownEl.css({ display: 'none' });
        }
    });
}

/** 监听input focus,并取消错误提示 */
function listenFocus() {
    $('.account-content-row-input').off('focus').on('focus', function () {
        $('.account-content-row-top-error').css({ display: 'none' });
    });
    $('.account-m-content-box-input').off('focus').on('focus', function () {
        // 修复安卓输入框撑起导致触发resize，导致闪烁的问题
        if (!isIOS()) {
            sessionStorage.setItem('inputFocusStorage','true');
        }
        $('.account-m-content-box-top-error').css({ display: 'none' });
    });
    $('.account-m-content-box-input').off('blur').on('blur', function () {
        // 修复安卓输入框撑起导致触发resize，导致闪烁的问题
        if (!isIOS()) {
            sessionStorage.setItem('inputFocusStorage','true');
        }
    });
}

/** 监听按钮点击事件 */
function listenBtnClick() {
    $('.account-content-btn').off('click').on('click', function (e) {
        throttleBtnClick(e);
    });
    $('#account-twitter').off('click').on('click', function (e) {
        throttleBtnClick(e);
    });
    $('#account-line').off('click').on('click', function (e) {
        throttleBtnClick(e);
    });
}

/** 移动端监听按钮点击事件 */
function listenMBBtnClick() {
    $('.account-m-content-btn').off('click').on('click', function (e) {
        throttleBtnClick(e);
    });
    $('#account-m-twitter').off('click').on('click', function (e) {
        throttleBtnClick(e);
    });
    $('#account-m-line').off('click').on('click', function (e) {
        throttleBtnClick(e);
    });
    $('#account-m-apple').off('click').on('click', function (e) {
        throttleBtnClick(e);
    });
}

/** 按钮增加节流 */
const throttleBtnClick = throttle(btnClick, 200);
/** 按钮点击事件 */
function btnClick(e)  {
    if (e.target && e.target.id) {
        switch (e.target.id) {
            case 'account-login':
            case 'account-m-login':
                handleLogin();
                break;
            case 'account-login-out':
            case 'account-m-login-out':
                switchLogin(false);
                break;
            case 'account-delete':
            case 'account-m-delete':
                    showModal({
                        title: 'アカウントを削除しますか',
                        content: 'アカウントとアプリを削除することは定期購読の解約になりません、ご注意ください。購読の管理と解約はGoogle PlayとApple Storeなどのアカウント設定で行なってください。',
                        confirmText: '削除する',
                        cancelText: 'キャンセル',
                        onConfirm: () => {
                            // 手机注销
                            let userInfo = sessionStorage.getItem('loginUserInfo');
                            try {
                                userInfo = JSON.parse(userInfo);
                            } catch (error) {
                                closeModal();
                            }
                            if (userInfo.source === 'mobile') {
                                loginWithDelete({ action: 'close', bduss: userInfo.bduss, country: userInfo.country }, function(res) {
                                    if (res && res.errno === 0) {
                                        sessionStorage.removeItem('loginUserInfo');
                                        switchLogin(false);
                                        closeModal();
                                    }
                                });
                            } else {
                                deleteAccount({ bduss: userInfo.bduss, system_version: '15.7.8', app_version: '12.4', device: 'ios', action: 'close' }, function(res) {
                                    if (res && res.errno === 0) {
                                        switchLogin(false);
                                        closeModal();
                                    }
                                },function() { closeModal(); });
                            }
                        },
                        onCancel: () => closeModal()
                    });
                break;
            case 'account-twitter':
            case 'account-m-twitter':
                getTwitterToken((res) => {
                    if (res && res.errno === 0) {
                        const { oauth_token, oauth_token_secret } = res.data;
                        twitterOauthTokenSecret = oauth_token_secret;
                        const url = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
                        window.open(url, '_blank');
                    }
                });
                break;
            case 'account-line':
            case 'account-m-line':
                window.location.href = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1438165207&redirect_uri=https%3A%2F%2Fsimeji.me&state=line&scope=profile%20openid';
                break;
            case 'account-m-apple':
                window.location.href = 'https://appleid.apple.com/auth/authorize?client_id=com.baidujp.Simeji.Web&redirect_uri=https%3A%2F%2Fsimeji.me&response_type=code%20id_token&state=apple&scope=&response_mode=fragment';
                break;
            default:
                break;
        }
    }
}
/** 手机登录 */
function handleLogin() {
    const country = $('#account-country-select').val() || $('account-m-country-select').val();
    const countryObj = areasArray.find(item => item.label === country);
    const mobile = $('#account-phone').val() || $('#account-m-phone').val();
    const pwd = $("#account-password").val() || $('#account-m-password').val();
    if (mobile && pwd && countryObj) {
        loginWithDelete({ action: 'login', mobile, pwd: MD5(pwd), country: countryObj.value }, function(res) {
            if (res && res.data && res.errno === 0) {
                try {
                    let result = decrypt(res.data);
                    result = JSON.parse(result);
                    const { bduss, user_name, portrait, profile } = result;
                    const loginInfo = { bduss, user_name, portrait, profile, source: 'mobile', country: countryObj.value };
                    sessionStorage.setItem('loginUserInfo', JSON.stringify(loginInfo));
                    toLogin(loginInfo);
                } catch(err) {
                    console.log(err);
                }
            } else {
                $('.account-content-row-top-error').css({ display: 'block' });
                $('.account-m-content-box-top-error').css({ display: 'block' });
            }
        });
    }
}
/**
 * 手机登录/注销
 * @param onSuccess
 */
function loginWithDelete(params, onSuccess, onError) {
    if (isQuery) {
        return;
    }
    isQuery = true;
    $.ajax({
        type: 'post',
        cache: false,
        data: encrypt(params),
        contentType: 'text/plain',
        dataType: 'json',
        async: true,
        url: mobileH5Url,
        success: function(res) {
            isQuery = false;
            onSuccess && onSuccess(res);
        },
        error: function (err) {
            isQuery = false;
            onError && onError();
            console.log(err);
        }
    });
}

/** 监听seesionStorage变化, 第三方登录 */
function storageEvent(e) {
    if (e.key === 'otherLoginInfo' && e.newValue) {
        let data;
        try {
            data = JSON.parse(e.newValue);
        } catch(err) {
            console.log(err);
        }
        if (data) {
            isQuery = true;
            let params = {};
            switch (data.source) {
                case 'line':
                case 'facebook':
                case 'gmail':
                case 'apple':
                    params = {
                        code: data.code,
                        type: data.source
                    };
                    break;
                // 推特为oauth1.0A,授权方式和其他不一致
                case 'twitter':
                    params = {
                        oauth_token: data.oauth_token,
                        oauth_verifier: data.oauth_verifier,
                        oauth_token_secret: twitterOauthTokenSecret,
                        type: data.source
                    };
                    break;
                default:
                    break;
            }
            $.ajax({
                type: 'get',
                cache: false,
                data: params,
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                dataType: 'json',
                async: true,
                url: otherLoginUrl,
                success: function (res) {
                    isQuery = false;
                    // 第三方登录成功 清除twitterSecret
                    twitterOauthTokenSecret = '';
                    if (res && res.data && res.errno === 0) {
                        const { bduss, user_name, portrait, profile } = res.data;
                        const loginInfo = { bduss, user_name, portrait, profile, source: data.source };
                        sessionStorage.setItem('loginUserInfo', JSON.stringify(loginInfo));
                        toLogin(loginInfo);
                    } else if (res && res.errno === 41) {
                        showModal({
                            title: '未登録のアカウントです',
                            confirmText: 'キャンセル',
                            showCancel: false,
                            onConfirm: () => closeModal(),
                        });
                    }
                },
                error: function () {
                    // 第三方登录成功 清除twitterSecret
                    twitterOauthTokenSecret = '';
                    isQuery = false;
                }
            });
        }
    }
}
/** 第三方登录的账号注销 */
function deleteAccount(params, onSuccess, onError) {
    if (isQuery) {
        return;
    }
    isQuery = true;
    $.ajax({
        type: 'post',
        cache: false,
        data: params,
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        dataType: 'json',
        async: true,
        url: otherAccountOutUrl,
        success: function(res) {
            isQuery = false;
            onSuccess && onSuccess(res);
        },
        error: function (err) {
            isQuery = false;
            onError && onError();
            console.log(err);
        }
    });
}
/** 获取twitter token */
function getTwitterToken(onSuccess, onError) {
    if (isQuery) {
        return;
    }
    isQuery = true;
    $.ajax({
        type: 'get',
        cache: false,
        data: {},
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        dataType: 'json',
        async: true,
        url: getTwitterTokenUrl,
        success: function(res) {
            isQuery = false;
            onSuccess && onSuccess(res);
        },
        error: function (err) {
            isQuery = false;
            onError && onError();
            console.log(err);
        }
    });
}
/** 切换登录/未登录界面 */
function switchLogin(isLogin) {
    if (!isLogin && sessionStorage.getItem('loginUserInfo')) {
        sessionStorage.removeItem('loginUserInfo');
    }
    $('#account-wait-login').css({ display: isLogin ? 'none' : 'block' });
    $('#account-m-wait-login').css({ display: isLogin ? 'none' : 'block' });
    $('#account-has-logined').css({ display: isLogin ? 'block' : 'none' });
    $('#account-m-has-logined').css({ display: isLogin ? 'block' : 'none' });
}

/** 登录状态展示 */
function toLogin(loginInfo) {
    const { user_name, portrait, profile } = loginInfo;
    $('div.account-content-user-right-title').text(user_name || '名称未設定');
    $('div.account-m-content-user-right-title').text(user_name || '名称未設定');
    $('div.account-conent-user-right-text').text(profile || '/');
    $('div.account-m-content-user-right-text').text(profile || '/');
    $('div.account-content-user-avatar').css('background-image', `url(${portrait || '../images/account/default_avatar.png'})`);
    $('div.account-m-content-user-avatar').css('background-image', `url(${portrait || '../images/account/default_avatar.png'})`);
    $('#account-phone').val('');
    $('#account-m-phone').val('');
    $("#account-password").val('');
    $('#account-m-password').val('');
    switchLogin(true);
}
function listenSessionStorage() {
    window.addEventListener('storage', storageEvent);
}

/**
 * 展示弹窗
 * @param options 弹窗的配置
 */
function showModal(options) {
    const { title, content, showConfirm = true, confirmText = '', onConfirm, showCancel = true, cancelText = '', onCancel } = options;
    let modalStr = '<div class="account-modal"><div class="account-modal-content">';
    title && (modalStr += `<div class="account-modal-content-title">${title}</div>`);
    content && (modalStr += `<div class="account-modal-content-text">${content}</div>`);
    showConfirm && (modalStr += `<div class="account-modal-content-confirm">${confirmText}</div>`);
    showCancel && (modalStr += `<div class="account-modal-content-cancel">${cancelText}</div>`);
    modalStr += `</div></div>`;
    $('.account').append(modalStr);
    $('.account-modal-content-confirm').off('click').on('click', function () {
        onConfirm && onConfirm();
    });
    $('.account-modal-content-cancel').off('click').on('click', function () {
        onCancel && onCancel();
    });
}

/**
 * 关闭弹窗
 */
function closeModal() {
    $('.account-modal').remove();
}

/**
 * mobile click menu
 */
function clickMenu() {
    $('.account-banner__top__menu').off('click').on('click', function() {
       $('side').fadeIn();
    });
}
/**
 * mobile click back top
 */

function clickBackTop() {
    $('.account-m-footer__backtop').off('click').on('click', function() {
        $('.mobile').animate({ scrollTop: 0 }, 600);
    });
}

/**节流 */
function throttle(func, delay) {
    let timerId;
    return function (...args) {
      if (timerId) {
        return;
      }
      timerId = setTimeout(() => {
        func.apply(this, args);
        timerId = undefined;
      }, delay);
    };
}

export function init() {
    // mobile
    clickMenu();
    clickBackTop();
    listenMBBtnClick();
    insertMbSelect(areasArray[0].label);
    // pc
    addAnimation();
    listenScroll();
    listenBtnClick();
    insertSelect(areasArray[0].label);

    // pc & mobile
    listenSelect();
    listenFocus();
    listenSessionStorage();
    const userInfo = sessionStorage.getItem('loginUserInfo');
    if (userInfo) {
        try {
            toLogin(JSON.parse(userInfo));
        } catch(err) {
            console.log(err);
        }
    }
    setTimeout(() => {
        const userLoginError = sessionStorage.getItem('loginUserError');
        if (userLoginError) {
            sessionStorage.removeItem('loginUserError');
            showModal({
                title: '未登録のアカウントです',
                confirmText: 'キャンセル',
                showCancel: false,
                onConfirm: () => closeModal(),
            });
        }
    }, 100);
}