import $ from 'jquery';
import { isIOS, getUrlParams, updateUrl, pcRouterMap, pcIdxMap, mbIdxMap, loadHtmlPath, adUrl } from './utlis';

/**
 * navbar 菜单
 */
let currIdx = '1';
let currPage = 'home';
// 选中态
const changeNavStyle = () => {
    $('li.side-nav__item').each(function() {
        const idx = this.getAttribute('index');
        if (currIdx === idx) {
            $(this).addClass('curr');
        } else {
            $(this).removeClass('curr');
        }
    });
};
// 跳转页面
const loadPage = () => {
    // $('view').html = '';
    if (currPage === 'ad') {
        window.location.href = adUrl;
        return;
    }
    $('view').load(`${loadHtmlPath}public/${currPage}.html`, (res) => {
        // 加载html
        $(this).html = res;
        // 引入js
        const { init } = require(`./${currPage}.js`);
        require(`../styles/${currPage}.scss`);
        require(`../styles/mobile/${currPage}.scss`);
        init && init();
        updateUrl('p', currPage);
    });
};

/**
 * pc & mobile click logo
 */

function clickLogo() {
    $('.side-logo').off('click').on('click', function() {
        const isLoad = currPage === this.getAttribute('pageName');
        currIdx = '1';
        currPage = 'home';
        changeNavStyle();
        !isLoad&& loadPage();
    });

    $('.side-header__logo').off('click').on('click', function() {
        const isLoad = currPage === this.getAttribute('pageName');
        currIdx = '1';
        currPage = 'home';
        changeNavStyle();
        !isLoad&& loadPage();
    });
}


/**
 * pc click nav item
 */
function clickNavItem() {
    $('li.side-nav__item').each(function() {
        $(this).on('click', function() {
            const isLoad = currPage === this.getAttribute('pageName');
            currIdx = this.getAttribute('index');
            currPage = this.getAttribute('pageName');
            changeNavStyle();
            !isLoad&& loadPage();
        });
    });
}

/**
 * pc to news page
 */
export function toNewsPage() {
    currIdx = '2';
    currPage = 'news';
    changeNavStyle();
    // loadPage();
}

/**
 * pc recruit btn
 */
function clickRecruit() {
    $('div.side-opt__recruit').off('click').on('click', function() {
        currIdx = '';
        currPage = 'recruit';
        changeNavStyle();
        loadPage();
   });
}

/**
 * pc ad btn
 */
function clickAd() {
    $('div.side-opt__ad').off('click').on('click', function() {
        window.open(adUrl, '_blank');
   });
}

/**
 * pc 菜单收缩
 */
let isOpen = false;
function clickMenu() {
    $('.side-menu').off('click').on('click', function() {
        if (!isOpen) {
            isOpen = true;
            $(this).addClass('open');
            $('.side-circle').addClass('zoom');
            $('.side-page').addClass('show');
            return;
        }
        isOpen = false;
        $(this).removeClass('open');
        $('.side-page').removeClass('show');
        $('.side-circle').removeClass('zoom');
    });
}

/**
 * mobile close btn
 */
function cliclClose() {
    $('.side-header__close').off('click').on('click', function() {
        $('side').fadeOut();
    });
}

/**
 * mobile click nav item
 */
 const changeMbNavStyle = () => {
    $('li.side-m-nav__item').each(function() {
        const idx = this.getAttribute('index');
        // window.mbVideo?.app && window.mbVideo.app.destroy({ texture: true });
        if (currIdx === idx) {
            $(this).addClass('currItem');
        } else {
            $(this).removeClass('currItem');
        }
    });
};

function clickMbNavItem() {
    $('li.side-m-nav__item').each(function() {
        $(this).off('click').on('click', function() {
            currIdx = this.getAttribute('index');
            currPage = this.getAttribute('pageName');
            if (['periodical', 'guide'].includes(currPage)) {
                $('view').load(`${loadHtmlPath}public/home.html`, () => {
                    // 引入js
                    const { init, changeSubPage } = require('./home.js');
                    require('../styles/home.scss');
                    require('../styles/mobile/home.scss');
                    init && init();
                    changeSubPage && changeSubPage(currPage === 'guide' ? '3' : '2', currPage);
                    updateUrl('p', currPage);
                });
                $('side').fadeOut();
                return;
            }
            changeMbNavStyle();
            loadPage();
            $('side').fadeOut();
        });
    });
}

/**
 * mobile click get app
 */
function getApp() {
    $('.side-m-footer__btn').off('click').on('click', function() {
        if (isIOS()) {
            window.open('https://go.onelink.me/J2a5/rt1n0tfx', '_blank');
            return;
        }
        window.open('https://go.onelink.me/J2a5/2pj2tcet', '_blank');
    });
}

function getPageInfo() {
    const { p } = getUrlParams();
    const pcIdx = pcIdxMap[p] || '1';
    const mbIdx = mbIdxMap[p] || '';
    const pcPage = pcRouterMap[p] || '';
    currIdx = $(window).width() > 1240 ? pcIdx : mbIdx;
    currPage = pcPage;
    changeNavStyle();
    changeMbNavStyle();
}

export function init() {
    getPageInfo();
    clickLogo();

    // mobile
    cliclClose();
    clickMbNavItem();
    getApp();

    // pc
    clickNavItem();
    clickRecruit();
    clickAd();
    clickMenu();
}

init();
