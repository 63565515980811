import $ from 'jquery';

/**
 * pc 处理scroll动画
 */
function listenScroll() {
    $('.faq-content').off('scroll').on('scroll', function () {
        if (!$(this)[0]) {
            return;
        }
        // 返回顶部
        const { scrollTop } = $(this)[0];

        // 头部伸缩
        if (scrollTop > 30) {
            $('.faq-header').addClass('scrollTop');
            $(this).addClass('scrollHeight');
            return;
        }
        $('.faq-header').removeClass('scrollTop');
        $(this).removeClass('scrollHeight');
    });
}

/**
 * pc 监听FAQ点击
 */
function listenItem() {
    $('div.faq-content__item').each(function() {
        $(this).off('click').on('click', function() {
            const isOpen = this.getAttribute('class').indexOf('open-main') !== -1;
            const idx = parseInt(this.getAttribute('index'), 10) - 1;
            $(`div.faq-content__item__main:eq(${idx})`).slideToggle();
            $(this)[isOpen ? 'removeClass' : 'addClass']('open-main');
        });
    });
}

/**
 * pc 入场动画
 */
 function addAnimation() {
    setTimeout(() => {
        $('.faq-pc .faq-header__left div:nth-child(1)').addClass('textEntry');
    }, 100);
    setTimeout(() => {
        $('.faq-pc .faq-header__left div:nth-child(2)').addClass('textEntry');
    }, 300);
    setTimeout(() => {
        $('.faq-pc .faq-header__left div:nth-child(3)').addClass('textEntry');
    }, 500);
}

/**
 * mobile 处理scroll动画
 */
 function listenMbScroll() {
    $('.faq-mobile').off('scroll').on('scroll', function() {
        if (!$(this)[0]) {
            return;
        }
        // 头部伸缩
        const { scrollTop } = $(this)[0];
        if (scrollTop >= 30) {
            $('.faq-banner__bottom__text').fadeOut('fast');
            $('.faq-banner').addClass('scrollTop');
            return;
        }
        $('.faq-banner__bottom__text').fadeIn('fast');
        $('.faq-banner').removeClass('scrollTop');
    });
}

/**
 * mobile click menu
 */
function clickMenu() {
    $('.faq-banner__top__menu').off('click').on('click', function() {
       $('side').fadeIn();
    });
}

/**
 * mobile click back top
 */
function clickBackTop() {
    $('.faq-m-footer__backtop').off('click').on('click', function() {
        $('.mobile').animate({ scrollTop: 0 }, 600);
    });
}

/**
 * pc 监听FAQ点击
 */
function listenMbItem() {
    $('div.faq-m-content__item').each(function() {
        $(this).off('click').on('click', function() {
            const isOpen = this.getAttribute('class').indexOf('open-main') !== -1;
            const idx = parseInt(this.getAttribute('index'), 10) - 1;
            $(`div.faq-m-content__item__main:eq(${idx})`).slideToggle();
            $(this)[isOpen ? 'removeClass' : 'addClass']('open-main');
        });
    });
}

export function init() {
    // mobile
    listenMbScroll();
    clickMenu();
    clickBackTop();
    listenMbItem();

    // pc
    addAnimation();
    listenScroll();
    listenItem();
}

$(function() {
    init();
});
